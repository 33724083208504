<template>
  <div class="no-page">
    <img class="no-page-404" src="../assets/images/404.png" alt="" />
    <div class="no-page-font">页面已失效，试试重新进入页面吧</div>
  </div>
</template>

<script>
export default {
  name: "NoPage",
};
</script>

<style lang="scss" scoped>
.no-page {
  .no-page-404 {
    display: block;
    width: 3.6rem;
    height: 3.6rem;
    margin: 0 auto;
    margin-top: 0.96rem;
    margin-bottom: 0.36rem;
  }

  .no-page-font {
    font-size: 0.28rem;
    font-family: PingFangSC, PingFangSC-Regular;
    font-weight: 400;
    text-align: center;
    color: #9e9e9e;
    line-height: 0.4rem;
  }
}
</style>
